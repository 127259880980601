/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:7b2e2c93-0f57-4000-9b94-e51835e27a34",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_bnPrpR2gY",
    "aws_user_pools_web_client_id": "1v72fqs3q1249c9m2dh70mg99k",
    "oauth": {}
};


export default awsmobile;
